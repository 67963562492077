<template>
  <Header />
  <section class="content">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
          <h1>{{ $t("address") }}</h1>
          <h4>{{ $t("min_pizza") }}</h4>
        </div>
      </div>
    </div>
    <div class="about-content white-bg">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-3 col-sm-12 col-12">
            <Pannel />
          </div>
          <div class="col-lg-9 col-md-9 col-sm-12 col-12 align-self-center">
            <AddressAddEdit />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import Header from "@/components/Header.vue"; // @ is an alias to /src
// @ is an alias to /src
import Pannel from "@/components/Pannel.vue"; // @ is an alias to /src
import useVuelidate from "@vuelidate/core";
import axios from "axios";
import { reactive, computed } from "vue";
import { required, minLength, maxLength } from "@vuelidate/validators";
import AddressAddEdit from "@/components/AddressAddEdit.vue"; // @ is an alias to /src
export default defineComponent({
  components: {
    Header,
    Pannel,
    AddressAddEdit
  },

  data() {
    return {
      
    };
  },
  methods: {

  },
});
</script>

<style></style>
